/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    exposure: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8.5 4a.5.5 0 00-1 0v2h-2a.5.5 0 000 1h2v2a.5.5 0 001 0V7h2a.5.5 0 000-1h-2zm-3 7a.5.5 0 000 1h5a.5.5 0 000-1z"/><path pid="1" d="M8 0a8 8 0 100 16A8 8 0 008 0M1 8a7 7 0 1114 0A7 7 0 011 8"/>',
    },
});
